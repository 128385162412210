<template>
  <div class="import-history">
    <div class="title">最近导入</div>
    <div class="history-list">
      <template v-for="(item, index) in list" :key="item.id">
        <div
          class="history-list-item"
          v-if="
            index < state.query.pageNo * state.query.pageSize &&
            index + 1 > (state.query.pageNo - 1) * state.query.pageSize
          "
        >
          <div class="text ellipsis">
            您于{{ item.createTime }}导入成功
            <span class="p-color">{{ item.successCount || 0 }}</span>
            位成员
            <template v-if="item.errorCount">
              ，导入失败
              <span class="red-color">{{ item.errorCount }}</span>
              位成员
            </template>
          </div>
          <el-button v-if="item.errorCount" type="text" :disabled="loading" @click.stop="downFailRecord(item.items)">
            下载导入失败记录
          </el-button>
        </div>
      </template>
    </div>
    <pagination
      @page-change="handleChangePageNo"
      v-model="state.query.pageNo"
      :page-size="state.query.pageSize"
      @size-change="handleChangePageSize"
      :total="list.length"
    />
    <!-- <div>
      <el-button type="text" @click="showMore">{{ state.isMore ? '隐藏' : '显示' }}更多</el-button>
    </div> -->
  </div>
</template>
<script>
import { reactive } from 'vue'
import { useDownErrorRecord } from '@/hooks/upload'
import Pagination from '@/components/pagination'
export default {
  components: { Pagination },
  name: 'importHistory',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    const state = reactive({
      query: {
        pageSize: 10,
        pageNo: 1
      }
    })
    // 切换pageNo
    const handleChangePageNo = pageNo => {
      state.query.pageNo = pageNo
      // queryPageList()
    }
    // 切换pageSize
    const handleChangePageSize = size => {
      state.query.pageSize = size
      state.query.pageNo = 1
      // queryPageList()
    }
    const { downFailRecord, loading } = useDownErrorRecord()
    return { downFailRecord, loading, state, handleChangePageNo, handleChangePageSize }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
