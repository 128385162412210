import { ref } from 'vue'
import { getImportHistoryList } from '@/apis/sys'
/**
 * 获取导入历史
 * @param {*} init 是否默认初始化
 * @returns
 */
export const useImportHistory = (init = false) => {
  const list = ref([])
  const initHistoryList = async () => {
    try {
      const result = await getImportHistoryList()
      console.log('result', result)
      list.value = result.data || []
    } catch (error) {
      console.log('getImportHistoryList error', error)
    }
  }
  if (init) {
    initHistoryList()
  }
  return { list, initHistoryList }
}
