<template>
  <div class="employee-import" v-if="!state.hasResult">
    <div class="header">
      <el-radio-group size="mini" v-model="state.type">
        <el-radio-button label="imp">批量导入成员</el-radio-button>
        <el-radio-button label="edit">导出/修改成员</el-radio-button>
      </el-radio-group>
    </div>
    <div class="container">
      <div class="step-wrapper">
        <div class="step">
          <div class="step-name">{{ viewText[state.type].stepFirstName }}</div>
          <div class="icon">
            <img :src="state.xlsIcon" alt="" />
          </div>
          <div class="btn-wrap down">
            <el-button round @click="handleDownClick" :loading="state.exportLoading">
              {{ viewText[state.type].downButtonName }}
            </el-button>
          </div>
          <div class="down-tips" v-if="state.expSuccess && state.type === 'edit'">
            <i class="iconfont el-icon-success s-color"></i>
            <span>导出通讯录成功</span>
            <button @click="downExportResult">下载</button>
          </div>
        </div>
        <div class="split-line"></div>
        <div class="step">
          <div class="step-name">{{ viewText[state.type].stepSecondName }}</div>
          <div class="icon">
            <img :src="state.xlsIcon" alt="" />
          </div>
          <div class="name">仅支持xls、xlsx格式文件</div>
          <div class="btn-wrap">
            <el-upload
              :accept="settings.accept"
              class="upload-demo"
              :show-file-list="false"
              :before-upload="beforeUpload"
              :http-request="httpRequest"
              :on-progress="onProgress"
              action="/"
              :limit="settings.limit"
            >
              <el-button round>上传文件</el-button>
            </el-upload>
          </div>
          <div class="progress-wrap" v-if="uploadState.processing">
            <el-progress :indeterminate="true" :duration="2" :stroke-width="10" :percentage="75">
              <div></div>
            </el-progress>
          </div>
        </div>
      </div>
      <ul class="desc">
        <li>导入会覆盖原有员工的信息，如需要更新已存在的员工，请先导出通讯录，再导出表格里进行修改</li>
        <li>子账号仅能上传和导出有权限范围内的通讯录信息。</li>
      </ul>
    </div>
    <import-history v-if="list.length" :list="list"></import-history>
  </div>
  <import-result v-else :data="state.importResult"></import-result>
</template>
<script>
import { reactive } from 'vue'
import { useImportHistory } from '../hooks'
import { useFileUpload, useDownFile } from '@/hooks/upload'
import ImportHistory from '../history/index.vue'
import ImportResult from '../result'
import { exportTemplate, importEmployee } from '@/apis'
import { useRoute } from 'vue-router'
import { XLS_ICON } from '@/common/const'
export default {
  name: 'employee-import',
  components: { ImportHistory, ImportResult },
  setup() {
    const route = useRoute()
    const department = route.query.depart
    const viewText = {
      imp: {
        stepFirstName: '1、下载通讯录模板,填写成员信息',
        stepSecondName: '2、上传填好的文件',
        downButtonName: '下载模板'
      },
      edit: {
        stepFirstName: '1、按部门导出员工信息',
        stepSecondName: '2、上传修改好的员工信息表',
        downButtonName: '导出'
      }
    }
    const state = reactive({
      xlsIcon: XLS_ICON,
      type: 'imp',
      exportLoading: false,
      expSuccess: false, //导出状态
      hasResult: false,
      importResult: {}
    })
    const { list } = useImportHistory(true)
    const {
      settings,
      state: uploadState,
      beforeUpload,
      httpRequest,
      onProgress
    } = useFileUpload(async file => {
      const { id } = file
      if (!id) return false
      const result = await importEmployee(id)
      state.importResult = result.data || {}
      state.hasResult = true
    })
    const { loading: downLoading } = useDownFile()
    const downExportResult = async () => {
      console.log('downLoading', downLoading)
      if (downLoading.value) return false
      downLoading.value = true
      await exportTemplate(false, [department])
      downLoading.value = false
    }
    const handleDownClick = async () => {
      if (state.exportLoading) return false
      state.exportLoading = true
      if (state.type === 'imp') {
        // 下载模板
        await exportTemplate(true)
        state.exportLoading = false
      } else {
        // 导出模板 这里模拟导出,因为请求接口直接返回文件了
        const timer = setTimeout(() => {
          clearTimeout(timer)
          state.expSuccess = true
          state.exportLoading = false
        }, 800)
      }
    }
    return {
      state,
      viewText,
      list,
      settings,
      uploadState,
      beforeUpload,
      httpRequest,
      onProgress,
      handleDownClick,
      downExportResult
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
