<template>
  <div class="import-result">
    <div class="icon-wrap">
      <i class="iconfont el-icon-warning w-color"></i>
    </div>
    <div class="result-text">
      成功导入{{ result.successCount || 0 }}人
      <template v-if="result.errorCount">
        ，失败
        <span class="red-color">{{ result.errorCount }}</span>
        人
      </template>
    </div>
    <div class="desc" v-if="result.errorCount">
      <span class="red-color">{{ result.errorCount }}条</span>
      错误记录，你可以下载未导入成功的成员 信息表，修改后重新上传
    </div>
    <div class="re-upload">
      <el-upload
        ref="uploadRef"
        :accept="settings.accept"
        class="upload-demo"
        :show-file-list="false"
        :before-upload="beforeUpload"
        :http-request="httpRequest"
        :on-progress="onProgress"
        :on-exceed="onExceed"
        action="/"
        :limit="settings.limit"
      >
        <el-button type="primary" size="small">再次上传</el-button>
      </el-upload>
    </div>
    <div class="down-error-list" v-if="result.errorCount">
      <el-button type="primary" size="small" :disabled="loading" @click.stop="downFailRecord(result.items)">
        下载失败记录
      </el-button>
    </div>
    <div class="table-wrapper" v-if="result.errorCount">
      <el-table empty-text="暂无数据" :data="result.logItems" header-row-class-name="custom-table-header">
        <el-table-column align="center" prop="rowIndex" width="80" label="行数"></el-table-column>
        <el-table-column align="center" prop="userid" label="员工ID"></el-table-column>
        <el-table-column align="center" show-overflow-tooltip prop="name" label="姓名" width="180">
          <template #default="scope">
            <wxview type="userName" :openid="scope.row.name">{{ scope.row.name }}</wxview>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="mobile" label="手机号码"></el-table-column>
        <el-table-column align="center" show-overflow-tooltip prop="department" label="部门">
          <template #default="scope">
            <span type="departmentName" :openid="scope.row.department">{{ scope.row.department }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="error" label="错误信息" width="180">
          <template #default="scope">
            <div class="text red-color">{{ scope.row.error.join(',') }}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import { useFileUpload, useDownErrorRecord } from '@/hooks/upload'
import { reactive, ref, toRefs } from 'vue'
import { importEmployee } from '@/apis'

export default {
  name: 'import-result',
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    let state = reactive({
      result: props.data
    })
    const uploadRef = ref(null)
    const {
      settings,
      state: uploadState,
      beforeUpload,
      httpRequest,
      onProgress,
      onExceed
    } = useFileUpload(
      async file => {
        const { id } = file
        if (!id) return false
        const result = await importEmployee(id)
        state.result = result.data || {}
      },
      {},
      uploadRef
    )
    const { downFailRecord, loading } = useDownErrorRecord()
    return {
      ...toRefs(state),
      settings,
      uploadRef,
      uploadState,
      beforeUpload,
      httpRequest,
      onProgress,
      onExceed,
      downFailRecord,
      loading
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
