<template>
  <div class="import-employee">
    <div class="header">
      <div class="back" @click.stop="handleBack">
        <i class="el-icon-arrow-left"></i>
        <span>返回</span>
      </div>
      <div class="text">批量导入/导出/修改成员</div>
    </div>
    <div class="content">
      <export-import></export-import>
    </div>
  </div>
</template>
<script>
import ExportImport from '@/views/components/export-import/index'
import { useRouter } from 'vue-router'
export default {
  name: 'employeeImport',
  components: { ExportImport },
  emits: ['back'],
  setup(props, { emit }) {
    const router = useRouter()
    const handleBack = () => {
      emit('back')
      router.back()
    }
    return { handleBack }
  }
}
</script>
<style lang="scss" scoped>
@import './employee-import.scss';
</style>
